<template>
  <div class="about_us">
    <Header></Header>
    <div class="about_us-content">
      <div class="title">{{ curLangInfo.aboutUsTit }}</div>
      <div class="content">{{ curLangInfo.aboutUs }}</div>
    </div>
    <div class="about_us-tips">
      <div class="content">{{ curLangInfo.Bible }}</div>
      <div class="from">{{ curLangInfo.from }}</div>
    </div>
    <div class="about_us-mission">{{ curLangInfo.mission }}</div>

    <!-- 认识我们的团队 -->
    <div class="about_us-team">
      <div class="title">{{ curLangInfo.menbersInfoTit }}</div>
      <TeamMember></TeamMember>
    </div>
    <!-- 联系我们 -->
    <ConnectUs></ConnectUs>
  </div>
</template>

<script setup>
import { computed } from "vue";
import Header from "../components/Header.vue";
import TeamMember from "../components/TeamMember.vue";
import ConnectUs from "../components/ConnectUs.vue";
import { useLangStore } from "../stores/index.ts";
import { aboutUs_cn } from "../js/chinese.js";
import { aboutUs_en } from "../js/english.js";

const langStore = useLangStore();
const curLangInfo = computed(() => {
  return langStore.curLang === "en" ? aboutUs_en : aboutUs_cn;
});
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .about_us {
    width: 960px;
    margin: 0 auto;
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 666px;
      margin: 0 auto;
      .title {
        margin: 20px 0;
        font-size: 34px;
        color: #3bbaf9;
      }
      .content {
        font-size: 20px;
        color: #7f7f7f;
      }
    }
    &-tips {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 739px;
      height: 130px;
      margin: 75px auto 55px;
      border-radius: 14px;
      color: #bf84f9;
      background-color: #ffedb4;
      .content {
        font-size: 32px;
      }
      .from {
        align-self: flex-end;
        margin: 10px 120px 0 0;
        font-size: 22px;
      }
    }
    &-mission {
      width: 666px;
      margin: 0 auto 103px;
      font-size: 20px;
      color: #7f7f7f;
    }
    &-team {
      width: 811px;
      margin: 0 auto;
      .title {
        margin: 20px 0;
        font-size: 34px;
        color: #3bbaf9;
      }
    }
  }
}
@media (max-width: 767px) {
  .about_us {
    width: 100%;
    margin: 0 auto;
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      margin: 0 auto;
      .title {
        margin: 20px 0;
        font-size: 34px;
        color: #3bbaf9;
      }
      .content {
        font-size: 20px;
        color: #7f7f7f;
      }
    }
    &-tips {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 180px;
      margin: 40px auto 55px;
      border-radius: 14px;
      color: #bf84f9;
      background-color: #ffedb4;
      .content {
        margin: 0 auto 10px 20px;
        font-size: 32px;
      }
      .from {
        align-self: flex-end;
        margin: 10px 30px;
        font-size: 22px;
      }
    }
    &-mission {
      width: 80%;
      margin: 0 auto 103px;
      font-size: 20px;
      color: #7f7f7f;
    }
    &-team {
      width: 80%;
      margin: 0 auto;
      .title {
        margin: 20px 0;
        font-size: 34px;
        color: #3bbaf9;
      }
    }
  }
}
</style>
