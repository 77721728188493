<template>
  <Header></Header>
  <div class="services">
    <div class="left">
      <div
        class="content"
        v-for="(item, index) in curLangInfo.leftInfo"
        :key="index"
      >
        <div class="content-first">
          <el-image :src="item.pic" class="pic" />
          <div class="title">{{ item.title }}</div>
        </div>
        <div
          class="content-hover content-hover--active"
          @click="toServices(item.path)"
        >
          <div class="title">{{ item.title }}</div>
          <el-image v-if="item.picHover" :src="item.picHover" class="pic" />
          <div v-else class="hover-text">
            {{ item.hoverText }}
            <div>{{ item.weHave }}</div>
          </div>
          <ul class="text">
            <li v-for="(text, textIndex) in item.contents" :key="textIndex">
              {{ text }}
            </li>
          </ul>
          <div class="more">
            {{ langStore.curLang === "en" ? "Learn More -->" : "瞭解更多 -->" }}
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div
        class="content"
        v-for="(item, index) in curLangInfo.rightInfo"
        :key="index"
      >
        <div class="content-first">
          <el-image :src="item.pic" class="pic" />
          <div class="title">{{ item.title }}</div>
        </div>
        <div
          class="content-hover content-hover--active"
          @click="toServices(item.path)"
        >
          <div class="title">{{ item.title }}</div>
          <el-image :src="item.picHover" class="pic" />
          <ul class="text">
            <li v-for="(text, textIndex) in item.contents" :key="textIndex">
              {{ text }}
            </li>
          </ul>
          <div class="more">
            {{ langStore.curLang === "en" ? "Learn More -->" : "瞭解更多 -->" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 联系我们 -->
  <ConnectUs></ConnectUs>
</template>

<script setup>
import { computed } from "vue";
import router from "../router";
import { useLangStore } from "../stores/index.ts";
import { ourService_cn } from "../js/chinese.js";
import { ourService_en } from "../js/english.js";
// 组件
import Header from "../components/Header.vue";
import ConnectUs from "../components/ConnectUs.vue";
const langStore = useLangStore();
const curLangInfo = computed(() => {
  return langStore.curLang === "en" ? ourService_en : ourService_cn;
});
const toServices = (path) => {
  router.push(path);
  window.scrollTo(0, 0);
};
</script>

<style lang="scss" scoped>
li {
  list-style: disc;
}
@media (min-width: 1024px) {
  .services {
    display: flex;
    width: 740px;
    margin: 51px auto 0;
    .left {
      display: flex;
      flex-direction: column;
      .content {
        position: relative;
        width: 353px;
        height: 255px;
        margin: 0 46px 36px 0;
        overflow: hidden;
        cursor: pointer;
        &-first {
          overflow: hidden;
          .title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 303px;
            height: 60px;
            padding-left: 50px;
            border-radius: 0 0 14px 14px;
            font-size: 32px;
            text-align: left;
            line-height: 60px;
            color: #bf84f9;
            background-color: #ffedb4;
          }
        }
        &-hover {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 353px;
          height: 100%;
          border-radius: 14px;
          background-color: #ffe9ac;
          transition: top 0.5s ease;
          .title {
            margin: 4px 0;
            padding-left: 37px;
            font-size: 34px;
            color: #ae80ff;
          }
          .pic {
            padding-left: 37px;
            width: 111px;
            height: 73px;
          }
          .text {
            padding-left: 42px;
            font-size: 14px;
            line-height: 22px;
            color: #7f7f7f;
          }
          .hover-text {
            width: 80%;
            margin-top: 20px;
            padding-left: 42px;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            color: #7f7f7f;
          }
          .more {
            margin-top: 8px;
            padding-left: 37px;
            font-size: 14px;
            color: #999999;
          }
        }
        &-hover--active {
          width: 353px;
          height: 100%;
          top: 100%;
        }
      }
      .content:hover .pic {
        top: 0;
      }
      .content:hover .content-hover {
        top: -2px;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      .content {
        position: relative;
        width: 351px;
        height: 314px;
        margin: 0 46px 36px 0;
        overflow: hidden;
        cursor: pointer;
        &-first {
          overflow: hidden;
          .title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 301px;
            height: 60px;
            padding-left: 50px;
            border-radius: 0 0 14px 14px;
            font-size: 32px;
            text-align: left;
            line-height: 60px;
            color: #bf84f9;
            background-color: #ffedb4;
          }
        }
        &-hover {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 353px;
          height: 100%;
          border-radius: 14px;
          background-color: #ffe9ac;
          transition: top 0.5s ease;
          .title {
            margin: 4px 0;
            padding-left: 37px;
            font-size: 34px;
            color: #ae80ff;
          }
          .pic {
            padding-left: 37px;
            width: 128px;
            height: 110px;
          }
          .text {
            padding-left: 42px;
            font-size: 14px;
            line-height: 22px;
            color: #7f7f7f;
          }
          .more {
            margin-top: 8px;
            padding-left: 37px;
            font-size: 14px;
            color: #999999;
          }
        }
        &-hover--active {
          width: 353px;
          height: 100%;
          top: 100%;
        }
      }
      .content:hover .pic {
        top: 0;
      }
      .content:hover .content-hover {
        top: -2px;
      }
    }
  }
}

@media (max-width: 767px) {
  .services {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 51px auto 0;
    .left {
      display: flex;
      flex-direction: column;
      .content {
        position: relative;
        width: 100%;
        height: 255px;
        margin: 0 0 36px 0;
        overflow: hidden;
        cursor: pointer;
        &-first {
          overflow: hidden;
          .title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 77vw;
            height: 60px;
            padding-left: 52px;
            border-radius: 0 0 14px 14px;
            font-size: 32px;
            text-align: left;
            line-height: 60px;
            color: #bf84f9;
            background-color: #ffedb4;
          }
        }
        &-hover {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 14px;
          background-color: #ffe9ac;
          transition: top 0.5s ease;
          .title {
            margin: 4px 0;
            padding-left: 37px;
            font-size: 34px;
            color: #ae80ff;
          }
          .pic {
            padding-left: 37px;
            width: 111px;
            height: 73px;
          }
          .text {
            padding-left: 42px;
            font-size: 14px;
            line-height: 22px;
            color: #7f7f7f;
          }
          .hover-text {
            width: 80%;
            margin-top: 20px;
            padding-left: 42px;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            color: #7f7f7f;
          }
          .more {
            margin-top: 8px;
            padding-left: 37px;
            font-size: 14px;
            color: #999999;
          }
        }
        &-hover--active {
          width: 100%;
          height: 100%;
          top: 100%;
        }
      }
      .content:hover .pic {
        top: 0;
      }
      .content:hover .content-hover {
        top: -2px;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      .content {
        position: relative;
        width: 100%;
        height: 314px;
        margin: 0 46px 36px 0;
        overflow: hidden;
        cursor: pointer;
        &-first {
          overflow: hidden;
          .title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 77vw;
            height: 60px;
            padding-left: 52px;
            border-radius: 0 0 14px 14px;
            font-size: 32px;
            text-align: left;
            line-height: 60px;
            color: #bf84f9;
            background-color: #ffedb4;
          }
        }
        &-hover {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 14px;
          background-color: #ffe9ac;
          transition: top 0.5s ease;
          .title {
            margin: 4px 0;
            padding-left: 37px;
            font-size: 34px;
            color: #ae80ff;
          }
          .pic {
            padding-left: 37px;
            width: 128px;
            height: 110px;
          }
          .text {
            padding-left: 42px;
            font-size: 14px;
            line-height: 22px;
            color: #7f7f7f;
          }
          .more {
            margin-top: 8px;
            padding-left: 37px;
            font-size: 14px;
            color: #999999;
          }
        }
        &-hover--active {
          width: 100%;
          height: 100%;
          top: 100%;
        }
      }
      .content:hover .pic {
        top: 0;
      }
      .content:hover .content-hover {
        top: -2px;
      }
    }
  }
}
</style>
