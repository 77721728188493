<template>
  <div class="s-content">
    <div class="s-content__title">{{ servicesContent.title }}</div>
    <div
      class="s-content__item"
      v-for="item in servicesContent.content"
      :key="item.contentTitle"
    >
      <div class="content">
        <div class="content__title">{{ item.contentTitle }}</div>

        <div v-if="!Array.isArray(item.contentText)" class="content__text">
          {{ item.contentText }}
        </div>
        <div
          v-else
          class="content__text"
          v-for="(item, index) in item.contentText"
          :key="index"
          v-html="item"
        ></div>
      </div>
      <el-image class="pic" :src="item.pic" />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  servicesContent: Object,
});
console.log(props);
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .s-content {
    width: 740px;
    margin: 90px auto 0;
    padding: 40px 36px 36px;
    background-color: #ffedb4;
    border-radius: 20px;
    &__title {
      margin-bottom: 40px;
      font-size: 36px;
      color: #bf84f9;
    }
    &__item {
      display: flex;
      margin-bottom: 44px;
      .content {
        width: calc(100% - 315px);
        color: #7f7f7f;
        &__title {
          font-size: 26px;
          margin-bottom: 14px;
        }
      }
      .pic {
        width: 315px;
        height: 186px;
        padding-left: 62px;
      }
      &:nth-child(odd) {
        flex-direction: row-reverse;
        .pic {
          padding-left: 0;
          padding-right: 62px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .s-content {
    width: 80%;
    margin: 0 auto;
    padding: 20px 20px 1px;
    background-color: #ffedb4;
    border-radius: 20px;
    &__title {
      margin-bottom: 40px;
      font-size: 26px;
      color: #bf84f9;
    }
    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 44px;
      .content {
        width: 100%;
        color: #7f7f7f;
        &__title {
          font-size: 22px;
          margin-bottom: 14px;
        }
      }
      .pic {
        width: 100%;
        height: 186px;
        padding-top: 20px;
      }
    }
  }
}
</style>
