<template>
  <h1 v-show="false">遊戲治療</h1>
  <h2 v-show="false">Play Therapy</h2>
  <ServicesTop :info="curLangInfo.servicesInfo"></ServicesTop>
  <ServicesContent
    :servicesContent="curLangInfo.servicesContent"
  ></ServicesContent>
  <ConnectUs></ConnectUs>
</template>

<script setup>
import { computed } from "vue";
import { useLangStore } from "../../stores/index.ts";
import { gameCure_cn } from "../../js/chinese.js";
import { gameCure_en } from "../../js/english.js";
const langStore = useLangStore();
const curLangInfo = computed(() => {
  return langStore.curLang === "en" ? gameCure_en : gameCure_cn;
});
// components
import ServicesTop from "../../components/ServicesTop.vue";
import ServicesContent from "../../components/ServicesContent.vue";
import ConnectUs from "../../components/ConnectUs.vue";
</script>

<style lang="scss" scoped></style>
