<template>
  <div class="Header">
    <div class="Header-logo" @click="toHome()"></div>
    <div class="Header-banner">
      <div class="Header-banner_intro"></div>
      <div class="Header-banner_buttons">
        <div class="buttons">
          <span
            class="button"
            v-for="(item, index) in curLangInfo.buttonList"
            :key="item"
            @click="changePage(index)"
            >{{ item }}</span
          >
        </div>
        <!-- tab -->
        <div class="swicth-tab">
          <div
            class="en"
            :class="{ isActive: langStore.curLang === 'en' }"
            @click="switchLang('en')"
          >
            En
          </div>
          <div
            class="cn"
            :class="{ isActive: langStore.curLang === 'cn' }"
            @click="switchLang('cn')"
          >
            中
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useLangStore } from "../stores/index.ts";

import { mianPage_cn } from "../js/chinese.js";
import { mianPage_en } from "../js/english.js";

const langStore = useLangStore();
const curLangInfo = computed(() => {
  return langStore.curLang === "en" ? mianPage_en : mianPage_cn;
});
const router = useRouter();
const changePage = (name) => {
  if (name === "关于") {
    router.push("/aboutUs");
  }
  switch (name) {
    case 0:
      router.push("/");
      break;
    case 1:
      router.push("/aboutUs");
      break;
    case 2:
      router.push("/services");
      break;
    case 3:
      router.push("/news");
      break;
    case 4:
      router.push("/connect");
      break;
    default:
      router.push("/");
      break;
  }
};

const toHome = () => {
  router.push("/");
};

const switchLang = (lang) => {
  langStore.curLang = lang;
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .Header {
    width: 960px;
    height: 140px;
    margin: 0 auto;
    &-logo {
      width: 400px;
      height: 92px;
      margin-left: 20px;
      background: url(../img/logo.svg) center bottom no-repeat;
      background-color: #fff;
      cursor: pointer;
    }
    &-banner {
      display: flex;
      align-items: center;
      width: 100%;
      height: 47px;
      background-color: #3bbaf9;
      &_intro {
        width: 390px;
        height: 22px;
        margin-left: 40px;
        background: url(../img/intro.svg) center center no-repeat;
        background-size: 390px 22px;
      }

      &_buttons {
        display: flex;
        align-items: center;
        .buttons {
          display: flex;
          justify-content: space-around;
          width: 342px;
          height: 22px;
          margin-left: 80px;
          color: #fff;
          .button {
            cursor: pointer;
          }
        }
        .swicth-tab {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 65px;
          height: 24px;
          margin-left: 4px;
          padding: 1px 0;
          border-radius: 13px;
          font-weight: 700;
          color: #3bbaf9;
          background-color: #fff;
          .en,
          .cn {
            width: 20px;
            height: 22px;
            line-height: 18px;
            padding: 0 5px;
          }
          .en {
            margin-left: 3px;
            cursor: pointer;
          }
          .cn {
            margin: 0 3px;
            cursor: pointer;
          }
          .isActive {
            border-radius: 13px;
            color: #fff;
            background-color: #3bbaf9;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .Header {
    width: 100%;
    &-logo {
      width: 90%;
      height: 80px;
      margin-left: 20px;
      background: url(../img/mobile/logo.svg) left bottom no-repeat;
      background-color: #fff;
      cursor: pointer;
    }
    &-banner {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 30px;
      background-color: #3bbaf9;
      &_intro {
        display: none;
        width: 100%;
        height: 18px;
        margin: 20px 0 10px 20px;
        background: url(../img/intro.svg) left center no-repeat;
      }
      &_buttons {
        display: flex;
        width: 100%;
        .buttons {
          display: flex;
          // align-items: center;
          justify-content: space-around;
          width: 100%;
          color: #fff;
          line-height: 30px;
          .button {
            cursor: pointer;
          }
        }
        .swicth-tab {
          position: absolute;
          top: 30px;
          right: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 65px;
          height: 26px;
          margin-left: 4px;
          padding: 1px 0;
          border-radius: 13px;
          font-weight: 700;
          color: #3bbaf9;
          background-color: #fff;
          .en,
          .cn {
            width: 20px;
            height: 22px;
            line-height: 18px;
            padding: 2px 5px 0;
          }
          .en {
            margin-left: 3px;
            cursor: pointer;
          }
          .cn {
            margin: 0 3px;
            cursor: pointer;
          }
          .isActive {
            border-radius: 13px;
            color: #fff;
            background-color: #3bbaf9;
          }
        }
      }
    }
  }
}
</style>
