<template>
  <h1 v-show="false">ChildrenOfGod</h1>
  <Header></Header>
  <div class="Index">
    <!-- 轮播图 -->
    <div class="home-pic"></div>
    <div class="Index-about_cure">
      <div class="cure_item cure_item--behavior">
        <div class="title">{{ curLangInfo.behaviorInfo.title }}</div>
        <div class="content">
          <div class="text">{{ curLangInfo.behaviorInfo.text }}</div>
        </div>
        <button class="whatLink" @click="toWhatApp"></button>
      </div>
      <div class="cure_item cure_item--game">
        <div class="title">{{ curLangInfo.gameInfo.title }}</div>
        <div class="content">
          <div class="text">{{ curLangInfo.gameInfo.text }}</div>
        </div>
      </div>
    </div>
    <!-- 我們的服務 -->
    <div class="Index-serve">
      <span>{{ curLangInfo.ourServiceTit }}</span>
      <div class="Index-serve_pics">
        <div
          class="pic_box"
          v-for="item in curLangInfo.serveInfo"
          :key="item"
          @click="toServices(item.path)"
        >
          <div class="box">
            <el-image
              :src="item.pic"
              :class="['pic', { isBig: !item.title }]"
            />
            <div v-if="item.title" class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="Index-serve_more" @click="toMore()">
        {{ langStore.curLang === "en" ? "Learn More --->" : "瞭解更多 --->" }}
      </div>
    </div>
    <!-- 家長見證 -->
    <div class="Index-witness">
      <p>{{ curLangInfo.parentsWitnessTit }}</p>
      <ul class="Index-witness_list">
        <li v-for="(item, index) in curLangInfo.parentsWitness" :key="index">
          <el-avatar class="parents-avatar" :size="'large'" :src="item.pic" />
          <div class="info">
            <p class="info-name">{{ item.name }}</p>
            <p class="info-comment">{{ item.comment }}</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 联系我们 -->
    <ConnectUs></ConnectUs>
  </div>
</template>

<script setup>
import { computed } from "vue";
import router from "../router";
import { useLangStore } from "../stores/index.ts";
import { mianPage_cn } from "../js/chinese.js";
import { mianPage_en } from "../js/english.js";

import Header from "../components/Header.vue";
import ConnectUs from "../components/ConnectUs.vue";
// import slidePic0 from "../img/slidePic0.svg";

// const slidePic = ref([slidePic0]);

const langStore = useLangStore();
const curLangInfo = computed(() => {
  return langStore.curLang === "en" ? mianPage_en : mianPage_cn;
});

const toServices = (path) => {
  router.push(path).then(() => {
    // 滚动到顶部
    window.scrollTo(0, 0);
  });
};

const toMore = () => {
  router.push("/services").then(() => {
    // 滚动到顶部
    window.scrollTo(0, 0);
  });
};

const toWhatApp = () => {
  window.open(
    "https://api.whatsapp.com/send?phone=85255958918&text=%E4%BD%A0%E5%A5%BD%EF%BC%81%E6%83%B3%E6%9F%A5%E8%A9%A2%E6%9C%8D%E5%8B%99%E8%A9%B3%E6%83%85",
    "_blank"
  );
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .Index {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 960px;
    margin: 0 auto;
    overflow-x: hidden;
    .home-pic {
      width: 960px;
      height: 406px;
      margin: -12px auto 0;
      background: url(../img/slidePic0.svg) center center no-repeat;
    }
    &-about_cure {
      position: relative;
      margin-top: 48px;
      margin-bottom: 90px;
      font-weight: 500;
      .cure_item {
        width: 795px;
        margin: 0 auto;
        .title {
          color: #48bbfc;
          font-size: 36px;
          margin-left: 10px;
        }
        .content {
          width: 100%;
          height: 278px;
          margin-left: 32px;
          background: url(../img/mainPage/behaviourCure.svg) 300px center
            no-repeat;
          background-size: 450px 278px;
          .text {
            width: 335px;
            margin-left: 32px;
            padding-top: 45px;
            color: #7f7f7f;
            line-height: 24px;
          }
        }
      }
      .cure_item--game {
        width: 683px;
        margin-top: 70px;
        .title {
          text-align: right;
          margin-bottom: -20px;
          margin-right: 40px;
        }
        .content {
          background: url(../img/mainPage/gameCure.svg) left top no-repeat;
          background-size: 281px 271px;
          .text {
            float: right;
            margin-top: 20px;
            margin-right: 100px;
          }
        }
      }
    }
    &-behavior {
      width: 795px;
      height: 337px;
      margin: 40px 0;
    }
    &-game {
      width: 646px;
      height: 296px;
      margin: 40px 0;
    }
    .whatLink {
      position: absolute;
      top: -40px;
      right: -80px;
      display: block;
      width: 84px;
      height: 84px;
      background-image: url(../img/whatsApp.png);
      background-color: #fff;
      background-size: 100%;
      border: none;
      cursor: pointer;
    }
    &-serve {
      width: 740px;
      span {
        color: #48bbfc;
        font-size: 36px;
        margin-left: 10px;
        // background-color: #fdd3d4;
      }
      &_pics {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 740px;
        margin-top: 10px;
        .pic_box {
          position: relative;
          display: flex;
          min-width: 223px;
          min-height: 185px;
          margin-bottom: 20px;
          border-radius: 14px;
          overflow: hidden;
          .box {
            .title {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 226px;
              height: 40px;
              font-size: 22px;
              text-align: center;
              line-height: 40px;
              color: #7f7f7f;
              background-color: #ffedb4;
            }
          }
          .pic {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scale(1.1);
            cursor: pointer;
            &:hover {
              transform: scale(1);
              transition: background-image 0.3s ease, transform 0.2s ease;
            }
          }
          .isBig {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            &:hover {
              transform: scale(1.2);
              transition: background-image 0.3s ease, transform 0.2s ease;
            }
          }
        }
      }
      &_more {
        width: 157px;
        height: 32px;
        margin-left: 10px;
        border-radius: 24px;
        border: 2px solid #ba88ff;
        background-color: #fff;
        background-size: 157px 32px;
        text-align: center;
        line-height: 28px;
        color: #ba88ff;
        transition: background-image 0.3s ease, transform 0.2s ease;
        cursor: pointer;
        &:hover {
          color: #fff;
          background-color: #ba88ff;
        }
      }
    }
    &-witness {
      width: 740px;
      height: 365px;
      margin: 100px auto 0;
      padding: 0 100px;
      border-radius: 20px;
      background-color: #ffecb1;
      p {
        color: #bf84f9;
        font-size: 36px;
        margin: 10px;
      }
      &_list {
        height: 260px;
        padding-right: 60px;
        overflow-y: scroll;
        /* 修改滚动条的轨道（背景）样式 */
        &::-webkit-scrollbar {
          width: 8px; /* 设置滚动条宽度 */
        }

        /* 修改滚动条的轨道背景颜色 */
        &::-webkit-scrollbar-track {
          background-color: #ffecb1;
          height: 12px;
        }

        /* 修改滚动条滑块（拖动的部分）样式 */
        &::-webkit-scrollbar-thumb {
          background-color: #bf84f9;
          max-height: 12px !important;
          border-radius: 5px; /* 设置滚动条滑块的圆角 */
        }

        li {
          list-style: none;
          display: flex;
          align-items: center;
          height: 100px;
          &:not(:last-child) {
            border-bottom: 2px solid #959595;
          }
          .parents-avatar {
            min-width: 56px;
            min-height: 56px;
          }
          .info {
            &-name {
              font-size: 16px;
              color: #a1a1a1;
            }
            &-comment {
              font-size: 18px;
              color: #7f7f7f;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .Index {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    .home-pic {
      min-width: 100%;
      height: 178vw;
      background: url(../img/mobile/slidePic0.svg) center center no-repeat;
    }
    &-about_cure {
      position: relative;
      margin-top: 48px;
      margin-bottom: 90px;
      font-weight: 500;
      .cure_item {
        width: 100%;
        margin: 0 auto;
        .title {
          color: #48bbfc;
          font-size: 36px;
          margin-left: 10px;
        }
        .content {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 400px;
          // margin-left: 32px;
          background: url(../img/mainPage/behaviourCure.svg) 60px 160px
            no-repeat;
          background-size: 80% 278px;
          .text {
            width: 88%;
            margin-left: 32px;
            padding-top: 45px;
            color: #7f7f7f;
            line-height: 24px;
          }
        }
      }
      .cure_item--game {
        width: 100%;
        height: 400px;
        margin-top: 50px;
        .title {
          text-align: left;
          margin-bottom: -20px;
          margin-right: 40px;
        }
        .content {
          background: url(../img/mainPage/gameCure.svg) 160px 160px no-repeat;
          background-size: 50% 271px;
          .text {
            float: left;
            margin-top: 20px;
            margin-right: 100px;
          }
        }
      }
    }
    .whatLink {
      position: absolute;
      top: -30px;
      right: 10px;
      display: block;
      width: 64px;
      height: 64px;
      background-image: url(../img/whatsApp.png);
      background-color: #fff;
      background-size: 100%;
      border: none;
      cursor: pointer;
    }
    &-serve {
      width: 100%;
      span {
        display: block;
        margin-left: 40px;
        margin-bottom: 20px;
        color: #48bbfc;
        font-size: 36px;
      }
      &_pics {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 10px;
        .pic_box {
          position: relative;
          display: flex;
          min-width: 80%;
          min-height: 225px;
          margin-bottom: 20px;
          border-radius: 14px;
          background-color: #ffebb2;
          overflow: hidden;
          &:nth-child(5) {
            display: none;
          }
          .box {
            .title {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 40px;
              font-size: 22px;
              text-align: center;
              line-height: 40px;
              color: #7f7f7f;
              background-color: #ffedb4;
            }
          }
          .pic {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scale(1.1);
            cursor: pointer;
            &:hover {
              transform: scale(1);
              transition: background-image 0.3s ease, transform 0.2s ease;
            }
          }
          .isBig {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            &:hover {
              transform: scale(1.2);
              transition: background-image 0.3s ease, transform 0.2s ease;
            }
          }
        }
      }
      &_more {
        width: 157px;
        height: 32px;
        margin-left: 40px;
        border-radius: 24px;
        border: 2px solid #ba88ff;
        background-color: #fff;
        background-size: 157px 32px;
        text-align: center;
        line-height: 28px;
        color: #ba88ff;
        transition: background-image 0.3s ease, transform 0.2s ease;
        cursor: pointer;
        &:hover {
          color: #fff;
          background-color: #ba88ff;
        }
      }
    }
    &-witness {
      width: 70%;
      height: 450px;
      margin: 50px auto 0;
      padding: 0 30px;
      border-radius: 20px;
      background-color: #ffecb1;
      p {
        color: #bf84f9;
        font-size: 36px;
        margin: 10px;
      }
      &_list {
        height: 330px;
        padding-right: 10px;
        overflow-y: scroll;
        /* 修改滚动条的轨道（背景）样式 */
        &::-webkit-scrollbar {
          width: 8px; /* 设置滚动条宽度 */
        }

        /* 修改滚动条的轨道背景颜色 */
        &::-webkit-scrollbar-track {
          background-color: #ffecb1;
          height: 12px;
        }

        /* 修改滚动条滑块（拖动的部分）样式 */
        &::-webkit-scrollbar-thumb {
          background-color: #bf84f9;
          max-height: 12px !important;
          border-radius: 5px; /* 设置滚动条滑块的圆角 */
        }

        li {
          list-style: none;
          display: flex;
          align-items: center;
          // height: 100px;
          &:not(:last-child) {
            border-bottom: 2px solid #959595;
          }
          .parents-avatar {
            min-width: 56px;
            min-height: 56px;
          }
          .info {
            &-name {
              font-size: 16px;
              color: #a1a1a1;
            }
            &-comment {
              font-size: 18px;
              color: #7f7f7f;
            }
          }
        }
      }
    }
  }
}
</style>
