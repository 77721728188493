<template>
  <ul class="member">
    <li
      class="member-item"
      v-for="(item, index) in curLangInfo.menbersInfo"
      :key="index"
    >
      <div class="pic_box">
        <el-image :src="item.pic" class="pic" />
      </div>
      <div class="name">{{ item.name }}</div>
      <div
        class="title"
        v-for="(title, titIndex) in item.title"
        :key="titIndex"
      >
        {{ title }}
      </div>
      <div
        v-for="(intro, introIndex) in item.intro"
        :key="introIndex"
        :class="['intro', { isFirstIntro: introIndex === 0 }]"
      >
        {{ intro }}
      </div>
    </li>
  </ul>
</template>

<script setup>
import { computed } from "vue";
import { useLangStore } from "../stores/index.ts";
import { aboutUs_cn } from "../js/chinese.js";
import { aboutUs_en } from "../js/english.js";

const langStore = useLangStore();
const curLangInfo = computed(() => {
  return langStore.curLang === "en" ? aboutUs_en : aboutUs_cn;
});
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}

@media (min-width: 1024px) {
  .member {
    display: flex;
    justify-content: space-between;
    width: 750px;
    margin-bottom: 95px;
    &-item {
      width: 234px;
      border-color: skyblue;
      .pic_box {
        position: relative;
        width: 223px;
        height: 185px;
        border-radius: 15px;
        overflow: hidden;
        .pic {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          &:hover {
            transform: scale(1.2);
            transition: background-image 0.3s ease, transform 0.2s ease;
          }
        }
      }
      .name {
        font-size: 26px;
        color: #bf84f9;
        margin: 8px 0 4px;
      }
      .title {
        font-size: 16px;
        line-height: 18px;
        color: #7f7f7f;
        &:last-child {
          margin-bottom: 10px;
        }
      }
      .intro {
        width: 240px;
        font-size: 12px;
        color: #b2b2b2;
        margin-top: 10px;
        line-height: 14px;
      }
      .isFirstIntro {
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 767px) {
  .member {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 35px;
    &-item {
      width: 100%;
      padding: 20px;
      border-radius: 14px;
      margin-bottom: 20px;
      border-color: skyblue;
      background-color: #ffebb2;
      .pic_box {
        position: relative;
        width: 100%;
        height: 220px;
        border-radius: 15px;
        overflow: hidden;
        .pic {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 26px;
        color: #bf84f9;
        margin: 8px 0 4px;
      }
      .title {
        font-size: 16px;
        line-height: 18px;
        color: #7f7f7f;
        &:last-child {
          margin-bottom: 10px;
        }
      }
      .intro {
        width: 240px;
        font-size: 12px;
        color: #b2b2b2;
        margin-top: 10px;
        line-height: 14px;
      }
      .isFirstIntro {
        margin-top: 20px;
      }
    }
  }
}
</style>
