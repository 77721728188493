<template>
  <Header></Header>

  <el-container class="news">
    <!-- 目錄 -->
    <el-aside class="left">
      <div class="left_title">目錄</div>
      <ul class="left_box">
        <li class="left_box_item" v-for="section in sections" :key="section.id">
          <a :href="`#${section.id}`" @click="scrollToSection(section.id)">{{
            section.title
          }}</a>
        </li>
      </ul>
    </el-aside>

    <!-- 右边文章内容 -->
    <el-main class="right">
      <div
        class="content_item"
        v-for="section in sections"
        :id="section.id"
        :key="section.id"
      >
        <div class="content_item_name">{{ section.title }}</div>
        <div
          class="content_item_text"
          v-for="(item, index) in section.content"
          :key="index"
          v-html="item"
        ></div>
      </div>
    </el-main>
  </el-container>
</template>

<script setup>
import Header from "../components/Header.vue";
import { ref } from "vue";

const sections = ref([
  {
    id: "blog1",
    title: "處理兒童焦慮",
    content: [
      `</br>焦慮係兒童常見的情緒。有啲小朋友比較容易緊張、怕事，但喺家長鼓勵下，仍然能夠慢慢嘗試。但有內在焦慮嘅孩子，除咗容易緊張之外，仲會有其他外顯行為，等我哋一齊了解吓啦！</br></br>`,
      "📌會因緊張而出現身體反應，例如肚痛，頭痛等",
      "📌容易出現逃避的行為",
      "📌因過分緊張，而難以入睡",
      "📌常常擔心未發生的事情",
      "📌容易因不確定的事情而感到焦躁易怒",
      "📌因不安而喜歡控制</br></br>",
      "如發現小朋友有以上特徵，家長就需要多加留意小朋友可能有焦慮嘅問題！</br></br>",
      "如果孩子真的有焦慮嘅情緒，家長可以怎樣輔助他們呢？</br></br>",
      "🫶🏻 體諒孩子的憂慮",
      "當看見孩子焦慮的行為，可以表示理解：「我知道你好擔心。」並用身體接觸安撫孩子，例如抱抱他或拍拍他的頭。</br></br>",
      "🫶🏻 耐心聆聽孩子的需要",
      "多聆聽孩子的憂慮，幫助他們拆解當中不合理的部分。</br></br>",
      "🫶🏻 運用提問，讓孩子認識自己的感受",
      "多用提問的方式，讓孩子更多的了解及認識自己的感受及想法。</br></br>",
      "🫶🏻 接受焦慮是孩子的一部份",
      "焦慮不是一時三刻可以放下嘅感受，接受孩子總會有擔心嘅事情，當孩子厝同一件事上有輕微改善，我們可以肯定他的努力：「你有努力嘗試！」</br></br>",
      "如有更多處理兒童焦慮的問題，歡迎聯絡我地🫶🏻一起擁抱孩子吧！",
      "———————————————————",
      "遊戲治療以遊戲作為橋樑，以觀察及與孩子互動的方式，讓孩子安全展現自己的內心世界、疏導情緒、建立自信及作出正向的改變。</br></br>",
      "COG的遊戲治療師為註冊輔導員及AutPlay® 治療師，致力為有需要兒童提供兒童為本遊戲治療，解構他們的心理健康問題。</br></br>",
      "歡迎WhatsApp 5595 8918 查詢有關遊戲治療詳情📞",
    ],
  },
  {
    id: "blog2",
    title: "3個小貼士 讓孩子學會坐定定",
    content: [
      "</br>想令到小朋友學識坐定定，並唔係一件容易嘅事！快啲留意我哋嘅小貼士，每日練習小朋友坐定定嘅技能啦！</br></br>",
      "1️⃣移除分散孩子注意力的物件</br>",
      "孩子未能安坐，可能與專注力有關。周遭太多有趣事物，集中力會大大減低，小朋友會被那些事物吸引，以致在練習中分心。我們可營造一個讓孩子專注的環境，製造一個專門用來練習的空間，讓孩子明白當處身於這個環境時，便需要安坐及聆聽。</br></br>",
      "2️⃣運用孩子喜歡的活動練習</br>",
      "在剛開始練習坐定定時，可選擇小朋友喜歡的活動，小朋友對活動有興趣，便會更願意坐下來長一點的時間。 隨後再選擇一兩個小朋友不抗拒的訓練，以喜歡的活動作結，以鼓勵孩子完成練習及延長安坐時間。</br></br>",
      "3️⃣使用感統工具輔助</br>",
      "有些孩子因感統問題或其他原因，安坐一段長時間對他們來說本來就是一個大的挑戰。我們可使用一些感統工具及練習去輔助他們。例如在練習安坐前，先進行一些動態活動，以滿足小朋友的感統需要。另外，亦可使用坐墊，使小朋友在安坐時仍能得到需要的感統刺激。</br>",
      "———————————————————</br>",
      "【一對一上門/中心訓練】</br>",
      "👩‍🏫COG導師為資深行為治療師，具多年特殊幼教經驗</br>",
      "⭐為每位小朋友度身訂造課程</br>",
      "⭐針對分析行為背後動機</br>",
      "⭐改善阻礙小朋友學習或日常生活的負面行為</br>",
      "⭐教導及增加正面行為</br>",
      "⭐訓練小朋友獨立參與各種活動</br>",
      "⭐全面提升語言、認知、社交、大小手肌等技能</br></br>",
      "歡迎各位家長透過WhatsApp 5595 8918 或 inbox 我地查詢😊",
    ],
  },
  {
    id: "blog3",
    title: "什麼是行為治療？",
    content: [
      "</br>行為治療原理是以行為分析科學，應用在理解及改變個人行為上的一種治療方式。</br></br>",
      "在制定個人學習計劃時，行為分析師會按照孩子的強弱項，以合適教學技巧及方法，有系統性及密集式地提升各項技能。</br></br>",
      "行為治療師會運用適當行為治療技巧，以有效改善行為問題，亦會課堂中與孩子建立良好關係，將訓練與遊玩融合，讓孩子輕鬆在遊戲中學習，能更有效將技巧轉化到日常生活中，大大提升治療成效。</br>",
      "- - - - - - - - - - - - - - - - - - - - - -</br>",
      "【一對一上門/中心訓練】</br>",
      "👩‍🏫COG導師均為資深行為治療師，具多年特殊幼教經驗</br>",
      "⭐為每位小朋友度身訂造課程</br>",
      "⭐針對分析行為背後動機</br>",
      "⭐改善阻礙小朋友學習或日常生活的負面行為</br>",
      "⭐教導及增加正面行為</br>",
      "⭐訓練小朋友獨立參與各種活動</br>",
      "⭐全面提升語言、認知、社交、大小手肌等技能</br></br>",
      "歡迎各位家長透過WhatsApp 5595 8918 或 inbox 我地查詢😊</br></br>",
    ],
  },
]);

function scrollToSection(sectionId) {
  const targetElement = document.getElementById(sectionId);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "smooth" });
  }
}
</script>

<style lang="scss">
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  color: #7f7f7f;
  &:visited {
    background-color: #fdd3d4;
  }
}
@media (min-width: 1024px) {
  .news {
    width: 940px;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
    .left {
      width: 220px;
      height: 235px;
      padding: 20px;
      border-radius: 15px;
      text-align: left;
      color: #7f7f7f;
      background-color: #f2f2f2;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
      &_title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      &_box {
        &_item {
          margin-bottom: 4px;
        }
      }
    }
    .el-main {
      --el-main-padding: 0;
    }
    .right {
      width: 452px;
      height: 80vh;
      margin: 0 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
      .content_item {
        padding: 38px;
        margin-bottom: 22px;
        border-radius: 15px;
        background-color: #ffedb4;
        &_name {
          font-size: 28px;
          color: #3bbaf9;
        }
        &_text {
          color: #7f7f7f;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .news {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    overflow: hidden;
    .left {
      display: none;
      width: 220px;
      height: 235px;
      padding: 20px;
      border-radius: 15px;
      text-align: left;
      color: #7f7f7f;
      background-color: #f2f2f2;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
      &_title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      &_box {
        &_item {
          margin-bottom: 4px;
        }
      }
    }
    .el-main {
      --el-main-padding: 0;
    }
    .right {
      width: 452px;
      height: 80vh;
      margin: 0 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
      .content_item {
        padding: 38px;
        margin-bottom: 22px;
        border-radius: 15px;
        background-color: #ffedb4;
        &_name {
          font-size: 28px;
          color: #3bbaf9;
        }
        &_text {
          color: #7f7f7f;
        }
      }
    }
  }
}
</style>
