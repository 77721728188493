<template>
  <Header></Header>
  <div class="s-top">
    <el-image :src="info.bannerPic" class="s-top__pic" />
    <div class="s-top__text">{{ info.bannerText }}</div>
    <div class="s-top__info">
      <div class="intro">
        <span v-html="info.info.intro1"></span>
        <p>{{ info.info.intro2 }}</p>
      </div>
      <el-image class="pic" :src="info.info.pic" />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import Header from "./Header.vue";

const props = defineProps({
  info: Object,
});
console.log(props);
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .s-top {
    position: relative;
    width: 960px;
    margin: -2px auto 0;
    &__pic {
      width: 960px;
      height: 100%;
    }
    &__text {
      position: absolute;
      width: 100%;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 72px;
      text-align: center;
      color: #7f7f7f;
    }
    &__info {
      display: flex;
      width: 740px;
      margin: 28px auto;
      color: #7f7f7f;
      .intro {
        width: calc(100% - 350px);
        padding: 0 35px;
        font-size: 16px;
        line-height: 26px;
      }
      .pic {
        width: 350px;
        height: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .s-top {
    position: relative;
    width: 100%;
    margin: -2px auto 0;
    &__pic {
      width: 100%;
      height: 100%;
    }
    &__text {
      position: absolute;
      width: 100%;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 36px;
      text-align: center;
      color: #7f7f7f;
    }
    &__info {
      display: flex;
      flex-direction: column-reverse;
      width: 80%;
      margin: 28px auto;
      color: #7f7f7f;
      .intro {
        width: 100%;
        padding-top: 20px;
        font-size: 16px;
        line-height: 26px;
      }
      .pic {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
