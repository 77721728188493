import { createRouter, createWebHistory } from "vue-router";
import Index from "../views/Index.vue";
import AboutUs from "../views/aboutUs.vue";
import Services from "../views/services.vue";
import Connect from "../views/connect.vue";
import News from "../views/news.vue";
// 我们的服务
import OneOnOne from "../views/services/oneOnOne.vue";
import GameCUre from "../views/services/gameCure.vue";
import SocialGroup from "../views/services/socialGroup.vue";
import SpActive from "../views/services/spActive.vue";
import TreePlan from "../views/services/treePlan.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/AboutUs",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/Services",
    name: "Services",
    component: Services,
  },
  {
    path: "/Connect",
    name: "Connect",
    component: Connect,
  },
  {
    path: "/News",
    name: "News",
    component: News,
  },
  // 我们的服务
  {
    path: "/OneOnOne",
    name: "OneOnOne",
    component: OneOnOne,
  },
  {
    path: "/GameCUre",
    name: "GameCUre",
    component: GameCUre,
  },
  {
    path: "/SocialGroup",
    name: "SocialGroup",
    component: SocialGroup,
  },
  {
    path: "/SpActive",
    name: "SpActive",
    component: SpActive,
  },
  {
    path: "/TreePlan",
    name: "TreePlan",
    component: TreePlan,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
