export const mianPage_en = {
  buttonList: ["Home", "About", "Our Service", "News", "Contact"],
  parentsWitnessTit: "Parent Testimonials",
  ourServiceTit: "Our Service",
  behaviorInfo: {
    title: "About ABA",
    text: "Based on the principles of Applied Behavior Analysis, ABA therapy aims to decrease maladaptive behaviors; increase appropriate behavior; enhance communication, social, and self-management skills of individuals.The goals are achieved through various behavior analytic techniques and reinforcement to develop appropriate behaviors and consolidate learned skills in natural environments.",
  },
  gameInfo: {
    title: "About Play Therapy",
    text: "Play therapy utilises child centered approach to reduce children's externalising and internalising behaviors; improve self-efficacy, self efficacy and social skills.It involves providing a safe, consistent and therapeutic environment; helping children experience acceptance and play out their deepest feelings; and guiding them to explore relationships and express desires.",
  },
  parentsWitness: [
    {
      pic: require("../img/witness/qqMom.webp"),
      name: "KiuKiu’s Mom",
      comment: "My daughter keeps asking me when she can come play again!",
    },
    {
      pic: require("../img/witness/Kaitlyn.webp"),
      name: "KaitAndy’s Mom",
      comment:
        "The teacher at school says he is sitting a lot better now, unlike before when he would run around in the classroom.",
    },
    {
      pic: require("../img/witness/kangNin.webp"),
      name: "Nathan’s Mom ",
      comment:
        "Thank you so much for giving my kid an opportunity to enjoy interacting with other children in the social social group！",
    },
    {
      pic: require("../img/witness/EvaMom.webp"),
      name: "Eva’s Mom",
      comment:
        "I think ABA does help my daughter as it is very systematic and my daughter is learning step-by-step.",
    },
  ],
  serveInfo: [
    {
      pic: require("../img/oneTrain.png"),
      title: "1-on-1 Training",
      path: "/OneOnOne",
    },
    {
      pic: require("../img/gameCure.png"),
      title: "Play Therapy",
      path: "/GameCure",
    },
    {
      pic: require("../img/samllTree.png"),
      title: "Sprout Program",
      path: "/TreePlan",
    },
    {
      pic: require("../img/soicalGroup.png"),
      title: "Social Skills",
      path: "/SocialGroup",
    },
    {
      pic: require("../img/mainPage/witness-en.svg"),
      title: "",
    },
    {
      pic: require("../img/spActive.png"),
      title: "Special Event",
      path: "/SpActive",
    },
  ],
};

export const aboutUs_en = {
  aboutUsTit: "About Us",
  aboutUs:
    "Children of God (HK) Limited was founded by two behavior therapists and a play therapist who show great enthusiasm in educating children with special needs. The name of the company comes from one of our founders. God spoke to her, and she envisioned starting a center named 'Children of God' helping all children learn and grow. She exposed herself to different opportunities to acquire various skills sets and knowledge. Five years later, HERE WE ARE!",
  Bible: "We love because he first loved us",
  from: "(1 John 4:19)",
  mission:
    "Spreading the love of God is our mission. In COG, we are eager to educate children with or without special needs, spread the gospel, communicate with and assist all parents in understanding their children and unleashing their full potential. All founders in COG share the same values, vision, and enthusiasm: We hope to help children explore their interests, develop their social, play, and communication skills so that they can integrate into society with greater confidence and power.",
  menbersInfoTit: "Meet Our Team",
  menbersInfo: [
    {
      pic: require("../img/wing-chan.png"),
      name: "Wing Chan",
      title: [
        "Co-founder,",
        "Course Director",
        "Board Certified Behavior Analyst (BCBA)",
      ],
      intro: [
        "Master of Education (Special Needs, Giftedness and Counselling), The Education University of Hong Kong",
        "Bachelor of Health Science (Hearing and Speech), The University of Sydney",
      ],
    },
    {
      pic: require("../img/pricilla-lee.png"),
      name: "Pricilla Lee",
      title: ["Co-founder", "Director", "Lead Behavior Therapist"],
      intro: [
        "Master of Psychology specialized in Applied Behavior Analysis, Capella University, MN",
        "Bachelor of Science in Psychology and Sociology, Bellevue College WA. Purdue University, IN",
      ],
    },
    {
      pic: require("../img/sarah-law.png"),
      name: "Sarah Law",
      title: [
        "Co-founder",
        "Certified Counsellor",
        "Certified AutPlayⓇ Therapist",
        "Play Therapist",
        "BCaBa",
      ],
      intro: [
        "Master of Social Science (Counselling), University of Hong Kong",
        "Bachelor of Science (Psychology), Royal Holloway, University of London",
      ],
    },
  ],
};
export const contactUs_en = {
  title: "Contact Us!",
  tips: "Let us know how we can help!",
  address: "Add: Flat A, 2/F, Kingswell Commercial",
  addressTips: ["Tower 171-173 Lockhart Road, Wan Chai, Hong Kong", ""],
  email: "Email：info@coghk.org",
  phone: "Phone/Whatsapp: +852 5595 8918",
  name: "Name：",
  emailOrPhone: "Email/Phone：",
  msg: "Message：",
  errorTips: "Please provide your name and email address or phone number",
  sendingTips: "Message~~~",
  successTips: "Well received",
  failTips: "Message failed ~",
};
export const ourService_en = {
  leftInfo: [
    {
      title: "1-on-1 Training",
      pic: require("../img/oneOnOne/one-by-one.png"),
      picHover: require("../img/ourService/1-on-1.svg"),
      path: "/OneOnOne",
      contents: [
        "Comprehensive ABA training",
        "Target essential skills development",
        "Eliminate children's inappropriate behaviors",
        "Increase independence",
      ],
    },
    {
      title: "Play Therapy",
      pic: require("../img/gameCure/game-cure.png"),
      picHover: require("../img/ourService/game.svg"),
      path: "/GameCure",
      contents: [
        "Toys are used to help children express their emotions",
        "Play out their feelings",
        "Explore relationships",
        "Express their desires and wishes freely",
      ],
    },
    {
      title: "Special Event",
      pic: require("../img/spActive/sp-active.png"),
      picHover: "",
      hoverText:
        "Occasional events for children to play the day out while learning!",
      weHave: "We've had:",
      path: "/SpActive",
      contents: [
        "One day social and emotion workshops",
        "Christmas social and emotion workshops",
        "Easter social and emotion workshops",
        "Summer courses",
      ],
    },
  ],
  rightInfo: [
    {
      title: "Social Skills",
      pic: require("../img/socialGroup/social-group.png"),
      picHover: require("../img/ourService/social.svg"),
      path: "/SocialGroup",
      contents: [
        "Students who have basic verbal skills are welcomed to join this class",
        "High teacher-to-student ratio",
        "To help children adapt to the social situations they may encounter in school and society",
      ],
    },
    {
      title: "Sprout Program",
      pic: require("../img/treePlan/tree-plan.png"),
      picHover: require("../img/ourService/tree-plan.svg"),
      path: "/TreePlan",
      contents: [
        "For children with emerging communication skills",
        "1-on-1 training in individual and group setting",
        "one-to-one shadowing in group to maximize transfer of skills learned in individual setting",
      ],
    },
  ],
};
export const oneOnOne_en = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-one.svg"),
    bannerText: "1-on-1 Training",
    info: {
      intro1:
        "Students will receive comprehensive ABA training to target various behaviors, including verbal behaviors, academics, social skills and self-management. Training will help to eliminate children's inappropriate behaviors, as well as to increase their independence.",
      intro2:
        "Based on your child's ability and needs, training programs are tailor-made by a Board Certified Behavior Analyst (BCBA) and therapy will be conducted by experienced behavior therapists under the supervision of a BCBA. Training can be conducted at home or in our center. ",
      pic: require("../img/services/one-by-one_info.png"),
    },
  },
  servicesContent: {
    title: "The Program Covers",
    content: [
      {
        contentTitle: "Communication skills",
        contentText:
          "Communication is critical in children's learning and growth. Through 1-on-1 training, children learn language that are functional to their daily living. Being able express themselves and understanding other is the key to explore the world.",
        pic: require("../img/services/one-by-one_content1.png"),
      },
      {
        contentTitle: "Develop appropriate play",
        contentText:
          "Play is critical in children's development as it is a medium for children to interact with the environment. Through developing play skills, children get to practice communication and social skills, and develop their imagination and creativity. Learning how to play involves various underlying skills, including imitation, cognitive skills and motor skills.",
        pic: require("../img/services/one-by-one_content2.png"),
      },
      {
        contentTitle: "Following instruction",
        contentText:
          "Being able to follow instructions is critical for children to thrive in home and school settings. Children unable to follow instructions can be attributed to underlying causes - motivation, understanding, attention, motor skills...",
        pic: require("../img/services/one-by-one_content3.png"),
      },
    ],
  },
  step: [
    {
      pic: require("../img/oneOnOne/survey.svg"),
      title: "Survey",
      text: "<br/><br/>Parents to fill in a survey and discuss their concerns",
    },
    {
      pic: require("../img/oneOnOne/class.svg"),
      title: "Trial sessions",
      text: "<br/></br>Supervisors and therapists meet the child to identify potential areas of focus",
    },
    {
      pic: require("../img/oneOnOne/plan.svg"),
      title: "Individualized programs",
      text: "Supervisors develop individualised programs based on the child's needs",
    },
    {
      pic: require("../img/oneOnOne/practice.svg"),
      title: "Monitor therapy progress",
      text: "Closely and continuously monitor treatment",
    },
    {
      pic: require("../img/oneOnOne/communication.svg"),
      title: "Regular Communication",
      text: "Regular communication between the therapy team and parents",
    },
  ],
};
export const gameCure_en = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-game.svg"),
    bannerText: "Play Therapy",
    info: {
      intro1:
        "A Play Therapist uses Child-Centered Play Therapy(CCPT) as her major approach. It is an evidence-based counselling approach for children aged 3 to 8 who are experiencing social, emotional, relationship and behavioural difficulties. Toys of different categories are used as a natural medium to facilitate child’s expressions.",
      intro2:
        "With a therapeutic environment provided by the play therapist including acceptance and permissiveness, children are able to play out their feelings, explore relationships and express their desires and wishes freely. ",
      pic: require("../img/gameCure/game-cure_info.png"),
    },
  },
  servicesContent: {
    title: "The Program Covers",
    content: [
      {
        contentTitle: "Reducing Acting-out behaviors",
        contentText:
          "Acting-out (externalising behaviors) include verbally or physically aggressive, or more severe than simple misbehavior. Acting-out (externalising behaviors) are often used to hide deep feelings. While the reasons behind the behaviors are often complex, play therapy can help by getting at the cause of the behaviors. ",
        pic: require("../img/gameCure/contentPic1.png"),
      },
      {
        contentTitle: "Reduce internalizing behaviors",
        contentText:
          "Internalizing behaviors reflects children's emotional and psychological states. Examples of internalizing behaviors include being nervous or irritable, feeling afraid and feeling sad. Play therapy acts as a way out for children who are emotionally or psychologically unstable to express their feelings and emotions. ",
        pic: require("../img/gameCure/contentPic2.png"),
      },
      {
        contentTitle: "Improve social skills",
        contentText:
          "For children to succeed in social situations, being able to show empathy, self-aware and self-regulate are all essential skills. Play therapy helps children develop these  social-emotional skills through play. ",
        pic: require("../img/gameCure/contentPic3.png"),
      },
      {
        contentTitle: "Improve self-esteem and self-efficacy",
        contentText:
          "Play therapy enhances children's self-esteem by helping them understand and believe in themselves. Children with high self-esteem strive for self-improvement.",
        pic: require("../img/gameCure/contentPic4.png"),
      },
    ],
  },
};

export const socialGroup_en = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-sk.svg"),
    bannerText: "Social Skills",
    info: {
      intro1:
        "Students who have basic verbal skills (ie., able to express their own will) are welcomed to join this class. The teachers to students ratio is 1:3 maximum. Games/work will be presented for students to adapt to the challenges people encounter in school and society. ",
      intro2:
        "In this advanced Social Class, various scenarios will be provided for students to cope with different types of issues. Our therapists will also provide reinforcement to every step the students perform correctly and redirection when they exhibit inappropriate behaviors. This class is highly beneficial to the children who lack social skills. ",
      pic: require("../img/socialGroup/social-group_info.png"),
    },
  },
  servicesContent: {
    title: "The Program Covers",
    content: [
      {
        contentTitle: "Communication skills",
        contentText:
          "Through designed scenarios, it provides great opportunities for children to facilitate their communication skills in natural settings. They learn to communicate and interact with peers in a safe environment.\n Children learn to ask for what they need and share materials with others in activities.",
        pic: require("../img/socialGroup/contentPic1.png"),
      },
      {
        contentTitle: "Social skills",
        contentText:
          "Essential social skills such as turn-taking, cooperating with others are taught in the form of role-play, group activities and fun games. Children are encouraged to practice the learned skills in various social scenarios. ",
        pic: require("../img/socialGroup/contentPic2.png"),
      },
      {
        contentTitle: "Emotion regulation skills",
        contentText:
          "Emotions play a huge role in developing and maintaining relationships. Being able to express their own feelings and identifying that of others helps children achieve social success. \n Children learn about emotions through role-playing various scenarios.",
        pic: require("../img/socialGroup/contentPic3.png"),
      },
    ],
  },
};

export const spActive_en = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-sp.svg"),
    bannerText: "Special Event",
    info: {
      intro1:
        "We have organised：<div>One day socio-emotional workshops</div><div>Christmas workshops </div><div>Summer programs</div>",
      intro2:
        "Spend the holidays by joining our joyful events, and having fun learning!",
      pic: require("../img/services/sp-active_info.png"),
    },
  },
  servicesContent: {
    title: "We have organised:",
    content: [
      {
        contentTitle: "One day socio-emotional workshops",
        contentText: [
          "Our thematic one day workshops, such as Ocean Explorer and Adventurous Journey, provide children valuable opportunities to learn various skills during play!</br></br>",
          "－ Thematic activities to help children acquire knowledge</br>",
          "－ Learn to regulate emotions</br>",
          "－ Learn to interact and work with others</br></br>",
        ],
        pic: require("../img/services/sp-active_content1.png"),
      },
      {
        contentTitle: "Christmas workshops ",
        contentText: [
          "Join our 3-day christmas workshops and celebrate the festival with us through fun events!</br></br>",
          "The course is designed by game therapists and behavioral therapists</br>",
          "－ Know and learn to adjust emotions</br>",
          "－ Improve your communication skills</br>",
          "－ Join and participate in group and co-operative games</br>",
          "－ A Certificate of Excellence will be issued upon completion of the course</br></br>",
        ],
        pic: require("../img/services/sp-active_content2.png"),
      },
      {
        contentTitle: "Summer programs",
        contentText: [
          "Summer socio-emotional camp and boardgame class designed for children of different levels and capabilities</br></br>",
          "－ Summer socio-emotional camp</br>",
          "&bull;&nbsp&nbsp&nbsp facilitating communication and social skills</br>",
          "&bull;&nbsp&nbsp&nbsp understanding emotions</br>",
          `&bull;&nbsp&nbsp&nbsp esolving conflicts</br></br>`,
          "－ Summer boardgame class</br>",
          "&bull;&nbsp&nbsp&nbsp Negotiating skills</br>",
          "&bull;&nbsp&nbsp&nbsp Teamwork and communication</br>",
          "&bull;&nbsp&nbsp&nbsp Planning and execution</br>",
          "&bull;&nbsp&nbsp&nbsp Improving attention</br>",
          "&bull;&nbsp&nbsp&nbsp Developing diverse interests</br></br>",
          "Courses are developed and conducted by internationally certified behavioral analysts and game therapists</br>",
        ],
        pic: require("../img/services/sp-active_content3.png"),
      },
    ],
  },
};

export const treePlan_en = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-tree.svg"),
    bannerText: "Sprout Program",
    info: {
      intro1:
        "This program is suitable for students whose communication and social skills are still emerging. The program aims to boost their social and communication skills in a small group setting, with adequate support for them to successfully participate in social scenarios. ",
      intro2:
        "Students joining this program will receive 1-on-1 support in individual and group settings to maximize learning. Students will receive 1-on-1 individual training to equip themselves with essential communication and social skills. In the group training sessions, students will receive shadowing to socialize with another student while learning the appropriate communication and social skills intensively with 1-on-1 support. Once the students are ready to socialize with other people, they may move to our Social Class (3 teachers : 6 students max.) to learn advanced social skills. ",
      pic: require("../img/treePlan/tree-plan_info.png"),
    },
  },
  servicesContent: {
    title: "The Program Covers",
    content: [
      {
        contentTitle: "Basic group skills",
        contentText:
          "Basic group skills are cornerstones for children to be successful in group learning in school setting. Choral responding, following simple group instructions and interacting with peers are all targeted in this program. ",
        pic: require("../img/treePlan/contentPic1.png"),
      },
      {
        contentTitle: "Cognitive skills",
        contentText:
          "The Sprout program aims to prepare children in mainstream school setting. Cognitive skills, including memory, perception, and attention, are core skills for children to carry out various tasks at school. ",
        pic: require("../img/treePlan/contentPic2.png"),
      },
    ],
  },
};
