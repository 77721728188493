<template>
  <div class="one-step">
    <div class="one-step__title">
      {{
        langStore.curLang === "en"
          ? "Steps to start 1-on-1 training"
          : "一對一訓練的步驟"
      }}
    </div>
    <ul class="one-step__content">
      <li
        class="one-step__item"
        v-for="(item, index) in curLangInfo.step"
        :key="index"
      >
        <el-image class="pic" :src="item.pic" />
        <div class="title">{{ item.title }}</div>
        <div class="text" v-html="item.text"></div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useLangStore } from "../stores/index.ts";
import { oneOnOne_cn } from "../js/chinese.js";
import { oneOnOne_en } from "../js/english.js";

const langStore = useLangStore();
const curLangInfo = computed(() => {
  return langStore.curLang === "en" ? oneOnOne_en : oneOnOne_cn;
});
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .one-step {
    width: 740px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 70px auto 0;
    &__title {
      margin-bottom: 72px;
      font-size: 34px;
      color: #3bbaf9;
    }
    &__content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &__item {
      width: calc(33% - 40px);
      margin-bottom: 60px;
      padding: 0 20px;
      font-size: 14px;
      color: #7f7f7f;
      text-align: center;
      .pic {
        width: 100px;
        height: 120px;
      }
      .title {
        margin: 20px 0 30px;
        color: #bc86fc;
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 767px) {
  .one-step {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 0;
    &__title {
      margin-bottom: 32px;
      font-size: 22px;
      color: #3bbaf9;
    }
    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
    }
    &__item {
      width: 80%;
      margin: 0 auto 30px;
      font-size: 16px;
      color: #7f7f7f;
      text-align: center;
      &:nth-child(1),
      &:nth-child(2) {
        .title {
          margin-bottom: -20px;
        }
      }
      .pic {
        width: 40%;
        height: 100%;
      }
      .title {
        margin-top: 10px;
        margin-bottom: 30px;
        color: #bc86fc;
        font-size: 24px;
      }
      .text {
        line-height: 20px;
      }
    }
  }
}
</style>
