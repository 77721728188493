export const mianPage_cn = {
  buttonList: ["主頁", "關於我們", "我們的服務", "新聞", "聯絡我們"],
  parentsWitnessTit: "家長見證",
  ourServiceTit: "我們的服務",
  behaviorInfo: {
    title: "關於行為治療",
    text: "行為治療是建基於應用行為分析理論（Applied Behavior Analysis)，目的為改善孩子行為問題、增強其溝通能力、社交、及自理 能力。課程中導師會運用正向強化及其他行為治療技巧，讓孩子建立適當的行為，並在日常環境中鞏固及應用所學到的技能。",
  },
  gameInfo: {
    title: "關於遊戲治療",
    text: "遊戲治療以兒童為本方針，以減低情緒問題、提升自信心、獨立能力及社交技能為目標。孩子會在安全及一致性的治療環境中，感受被接納，及以玩具及遊戲表達內心最深層的想法。導師會給予引導，讓孩子探索他與自己及與他人的關係，並表達自己的需求。",
  },
  parentsWitness: [
    {
      pic: require("../img/witness/qqMom.webp"),
      name: "蕎蕎媽媽",
      comment: "我個女成日提住要上去玩二人三足，佢隔幾日就問幾時可以再去。",
    },
    {
      pic: require("../img/witness/Kaitlyn.webp"),
      name: "Kaitlyn",
      comment: "多謝你地，姐姐， 我都好開心呀，下次嗰度玩啦！",
    },
    {
      pic: require("../img/witness/kangNin.webp"),
      name: "康寧",
      comment: "有捐山窿，volcano，角色扮演，好好玩！",
    },
    {
      pic: require("../img/witness/EvaMom.webp"),
      name: "Eva媽媽",
      comment: "我覺得ABA可以幫到我個女，佢係好系統式既，逐步逐步咁教小朋友。",
    },
  ],
  serveInfo: [
    {
      pic: require("../img/oneTrain.png"),
      title: "一對一訓練",
      path: "/OneOnOne",
    },
    {
      pic: require("../img/gameCure.png"),
      title: "遊戲治療",
      path: "/GameCure",
    },
    {
      pic: require("../img/samllTree.png"),
      title: "小樹苗計劃",
      path: "/TreePlan",
    },
    {
      pic: require("../img/soicalGroup.png"),
      title: "社交小組",
      path: "/SocialGroup",
    },
    {
      pic: require("../img/mainPage/witness-cn.svg"),
      title: "",
    },
    {
      pic: require("../img/spActive.png"),
      title: "特別活動",
      path: "/SpActive",
    },
  ],
};

export const aboutUs_cn = {
  aboutUsTit: "關於我們",
  aboutUs:
    "Children of God (HK) Limited 是由三位具教育特殊需要兒童充滿熱誠的行為治療師及遊戲治療師所創立。神曾對其中一位創辦人指示，將來會成立一所名為Children of God 、協助兒童發展及成長的中心。她從各處獲取技能及經驗，五年後，COG正式成立。",
  Bible: "我們愛，因為神先愛我們。",
  from: "(約翰一書4:19)",
  mission:
    "傳揚神大愛是我們的使命。在COG，我們致力培育所有有各項需要的兒童； 與家長緊密聯繫及給予協助；讓孩子得以被理解，並能發揮他們的天賦與所長。 COG 創辦人有著同一個信念：我們希望孩子能探索自己的興趣、發展出遊戲、社交、溝通技巧，使他們能以自信及能力應對社會",
  menbersInfoTit: "認識我們的團隊",
  menbersInfo: [
    {
      pic: require("../img/wing-chan.png"),
      name: "陳曉穎",
      title: ["聯合創辦人", "課程總監", "國際認證行為分析師（ BCBA ）"],
      intro: [
        "香港教育大學教育碩士（特殊需要、資優及輔導）",
        "悉尼大學健康科學學士（聽力和言語）",
      ],
    },
    {
      pic: require("../img/pricilla-lee.png"),
      name: "利穎思",
      title: ["聯合創辦人", "課程總監", "首席行為治療師"],
      intro: [
        "美國凱佩拉大學理學碩士(行為分析治療)",
        "美國普渡大學理學學士(心理學)",
        "美國普渡大學文學學士 (社會學)",
      ],
    },
    {
      pic: require("../img/sarah-law.png"),
      name: "羅君慈",
      title: [
        "聯合創辦人",
        "認證輔導員",
        "AutPlay（ R ）治療師",
        "遊戲治療師",
        "國際認證助理行為分析師",
      ],
      intro: [
        "香港大學社會科學碩士（輔導）",
        "倫敦大學皇家霍洛威學院理學學士（心理學）",
      ],
    },
  ],
};
export const contactUs_cn = {
  title: "聯絡我們",
  tips: "想找我們談談嗎？",
  address: "地址： 香港灣仔駱克道171-173號",
  addressTips: ["金威商業大廈2樓A室", "(灣仔地鐵站A1出口步行兩分鐘)"],
  email: "邮箱：info@coghk.org",
  phone: "電話/Whatsapp: +852 5595 8918",
  name: "姓名：",
  emailOrPhone: "郵箱/電話：",
  msg: "留言：",
  errorTips: "需要提供姓名和郵箱或電話哦",
  sendingTips: "留言中~~~",
  successTips: "謝謝你的留言!",
  failTips: "留言失败~",
};
export const ourService_cn = {
  leftInfo: [
    {
      title: "一對一訓練",
      pic: require("../img/oneOnOne/one-by-one.png"),
      picHover: require("../img/ourService/1-on-1.svg"),
      path: "/OneOnOne",
      contents: [
        "全面的行為治療（ABA）訓練",
        "針對各項必要技能發展",
        "改善行為問題",
        "培養孩子獨立",
      ],
    },
    {
      title: "遊戲治療",
      pic: require("../img/gameCure/game-cure.png"),
      picHover: require("../img/ourService/game.svg"),
      path: "/GameCure",
      contents: [
        "以玩具協助孩子表達情緒",
        "紓發內心感受",
        "探索自己與社會的關係",
        "自由地表達需求及願望",
      ],
    },
    {
      title: "特別活動",
      pic: require("../img/spActive/sp-active.png"),
      picHover: "",
      hoverText: "在特別節日讓孩子在學習之餘，也享受愉快時光！",
      weHave: "我們曾舉辦:",
      path: "/SpActive",
      contents: [
        "一日情緒社交工作坊",
        "聖誕情緒社交工作坊",
        "復活節情緒社交工作坊",
        "暑期課程",
      ],
    },
  ],
  rightInfo: [
    {
      title: "社交小組",
      pic: require("../img/socialGroup/social-group.png"),
      picHover: require("../img/ourService/social.svg"),
      path: "/SocialGroup",
      contents: [
        "適合擁有基礎語言能力的孩子",
        "高師生比例",
        "幫助孩子適應各種社交場景",
      ],
    },
    {
      title: "小樹苗計劃",
      pic: require("../img/treePlan/tree-plan.png"),
      picHover: require("../img/ourService/tree-plan.svg"),
      path: "/TreePlan",
      contents: [
        "適合表達能力較弱的孩子",
        "結合一對一及社交小組訓練",
        "小組提供影子老師協助，以提升學習成效",
      ],
    },
  ],
};
export const oneOnOne_cn = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-one.svg"),
    bannerText: "一對一訓練",
    info: {
      intro1:
        "學生將接受行為治療（ ABA），訓練會全方位提升各種正面行為，包括言語、認知、社交技能和自我管理能力。訓練亦針對減低不當行為，並增強孩子的獨立性。",
      intro2:
        "根據您孩子的能力和需求，我們的國際認證行為分析師 (BCBA) 會為孩子定制個人學習計劃，並由經驗豐富的行為治療師在 BCBA 的監督下進行訓練。家長可選擇在家居或在我們的中心接受訓練。",
      pic: require("../img/services/one-by-one_info.png"),
    },
  },
  servicesContent: {
    title: "一對一訓練涵蓋",
    content: [
      {
        contentTitle: "溝通技巧",
        contentText:
          "溝通對於兒童的學習和成長至關重要。透過一對一訓練，孩子能學習對他們日常生活有用的語言，能夠表達自己和理解他人，助他們探索世界",
        pic: require("../img/services/one-by-one_content1.png"),
      },
      {
        contentTitle: "發展遊戲技巧",
        contentText:
          "遊戲是兒童與環境互動的媒介，因此是兒童發展的一大基礎。通過發展遊戲技巧，孩子們可以練習溝通和社交技能，並發展他們的想像力和創造力。遊戲技巧涉及各種基本技能，包括模仿、認知技能和運動技能。",
        pic: require("../img/services/one-by-one_content2.png"),
      },
      {
        contentTitle: "跟從指示",
        contentText:
          "孩子能夠在家庭和學校環境中遵循指示，才能茁壯成長。孩子難以遵循指示，可能是基於以下原因：動機、理解力、注意力、運動技能等……",
        pic: require("../img/services/one-by-one_content3.png"),
      },
    ],
  },
  step: [
    {
      pic: require("../img/oneOnOne/survey.svg"),
      title: "調查",
      text: "家長填寫調查表，讓治療團隊初步了解孩子狀況",
    },
    {
      pic: require("../img/oneOnOne/class.svg"),
      title: "試堂",
      text: "行為分析師和治療師與孩子進行評估及初步訓練，以擬定訓練方向及目標",
    },
    {
      pic: require("../img/oneOnOne/plan.svg"),
      title: "定制個人學習計劃",
      text: "行為分析師根據孩子及家庭的需要，制定個人學習計劃",
    },
    {
      pic: require("../img/oneOnOne/practice.svg"),
      title: "監測訓練進展",
      text: "密切和持續監測訓練進展",
    },
    {
      pic: require("../img/oneOnOne/communication.svg"),
      title: "定期溝通",
      text: "治療團隊與家長之間定期溝通",
    },
  ],
};
export const gameCure_cn = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-game.svg"),
    bannerText: "游戲治療",
    info: {
      intro1:
        "遊戲治療師使用以兒童為本遊戲治療（Child-Centered Play Therapy，CCPT）作為主要辅导方法。這種方法专门針對3至8歲、有社交、情緒、或行為困難的兒童。不同類別的玩具會被用作幫助孩子表達的媒介。",
      intro2:
        "在遊戲治療師提供的安全及包容的治療環境中，孩子可以自由地表達自己的感受、探索關係、表達他們的願望和希望。",
      pic: require("../img/gameCure/game-cure_info.png"),
    },
  },
  servicesContent: {
    title: "課程內容涵蓋",
    content: [
      {
        contentTitle: "減少外化行為",
        contentText:
          "外化行為包括言語或身體攻擊、或更嚴重的行為。外化行為通常是為了隱藏深層的感受。儘管行為背後的原因往往複雜，但遊戲治療可以通過找到行為的原因來幫助他。",
        pic: require("../img/gameCure/contentPic1.png"),
      },
      {
        contentTitle: "減少內化行為",
        contentText:
          "內化行為的例子包括緊張或易怒、感到害怕和感到悲傷。這些行為反映了兒童的情感和心理狀態。遊戲治療能讓情感或心理不穩定的兒童表達自己的所感所想。",
        pic: require("../img/gameCure/contentPic2.png"),
      },
      {
        contentTitle: "提升社交技能",
        contentText:
          "能夠展示同理心、自我意識和自我調節都是必不可少的社交技能。通過遊戲治療，能有助兒童發展這些社交情緒技能，使他們能融入社會。",
        pic: require("../img/gameCure/contentPic3.png"),
      },
      {
        contentTitle: "提升自尊和自我效能",
        contentText:
          "遊戲治療通過幫助兒童理解和相信自己，來增強他們的自尊心。自信心高的兒童會更勇敢、努力達成改進自己的目標。",
        pic: require("../img/gameCure/contentPic4.png"),
      },
    ],
  },
};

export const socialGroup_cn = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-sk.svg"),
    bannerText: "社交小組",
    info: {
      intro1:
        "課程內容由行為治療師及遊戲治療師編定及指導，以遊戲形式及行為治療方針訓練社交及溝通技巧，配以每月不同主題，讓孩子能靈活應對不同社交場合。",
      intro2:
        "社交小組適合能運用簡單言語表達的孩子。師生比例最多1:3，以照顧個別孩子需要。",
      pic: require("../img/socialGroup/social-group_info.png"),
    },
  },
  servicesContent: {
    title: "社交小組",
    content: [
      {
        contentTitle: "溝通技巧",
        contentText:
          "透過不同活動，讓孩子提升溝通技巧，以聆聽別人、參與互動，及正確表達自己的想法。",
        pic: require("../img/socialGroup/contentPic1.png"),
      },
      {
        contentTitle: "社交技巧",
        contentText:
          "在群體遊戲、模擬情境等不同的社交情境中，讓孩子練習輪流、合作等必要社交技巧。",
        pic: require("../img/socialGroup/contentPic2.png"),
      },
      {
        contentTitle: "情緒管理技巧",
        contentText:
          "情緒管理對於建立人際關係中非常重要。透過角色扮演，學會正確表達及控制自己的情緒，以及換位思考、理解別人的情緒，孩子便能更容易在社交上取得成功。",
        pic: require("../img/socialGroup/contentPic3.png"),
      },
    ],
  },
};

export const spActive_cn = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-sp.svg"),
    bannerText: "特別活動",
    info: {
      intro1:
        "我們曾舉辦：<div>一日情緒社交工作坊</div><div>聖誕情緒社交工作坊</div><div>暑期課程</div>",
      intro2: "在特別節日讓孩子在學習之餘，也享受愉快時光！",
      pic: require("../img/services/sp-active_info.png"),
    },
  },
  servicesContent: {
    title: "我們曾舉辦:",
    content: [
      {
        contentTitle: "一日情緒社交工作坊",
        contentText: [
          "我們的COG一日情緒社交工作坊設有不同主題，包括海洋探索及各國探險之旅~除了讓小朋友渡過愉快的時光，更能訓練社交技能及提升情緒管理技巧!</br></br>",
          "－ 透過主題式活動，增進認知</br>",
          "－ 認識及學習調適情緒</br>",
          "－ 增加與人合作交流的機會</br></br>",
        ],
        pic: require("../img/services/sp-active_content1.png"),
      },
      {
        contentTitle: "聖誕情緒社交工作坊",
        contentText: [
          "一連三日嘅Christmas Camp ，讓小朋友練習控制情緒同增進社交技巧！趁著呢個聖誕假期，參加 COG舉辦既反轉聖誕大本營啦！</br></br>",
          "課程由遊戲治療師及行為治療師設計，內容包括：</br>",
          "－ 認識及學習調適情緒</br>",
          "－ 增進溝通技巧</br>",
          "－ 加入及參與團體及合作遊戲</br>",
          "－ 完成課程後會頒發精美證書一張</br></br>",
        ],
        pic: require("../img/services/sp-active_content2.png"),
      },
      {
        contentTitle: "暑期課程",
        contentText: [
          "在暑假期間，我們會分別增設暑期情緒社交班及桌遊社交班，以團體遊戲及桌遊作媒介，讓不同能力的小朋友提升社交及情緒管理能力。</br></br>",
          "－ 情緒社交夏令營</br>",
          "&bull;&nbsp&nbsp&nbsp針對溝通技巧及社交技巧</br>",
          "&bull;&nbsp&nbsp&nbsp認識及理解情緒</br>",
          `&bull;&nbsp&nbsp&nbsp解決衝突</br></br>`,
          "－ 暑期桌遊社交班</br>",
          "&bull;&nbsp&nbsp&nbsp談判協商</br>",
          "&bull;&nbsp&nbsp&nbsp團隊合作及溝通</br>",
          "&bull;&nbsp&nbsp&nbsp規劃決策</br>",
          "&bull;&nbsp&nbsp&nbsp提升專注力</br>",
          "&bull;&nbsp&nbsp&nbsp建立多元興趣</br></br>",
          "課程由國際認證行為分析師及遊戲治療師編製及指導</br>",
        ],
        pic: require("../img/services/sp-active_content3.png"),
      },
    ],
  },
};

export const treePlan_cn = {
  servicesInfo: {
    bannerPic: require("../img/ourService/banner-tree.svg"),
    bannerText: "小樹苗言語社交培育計劃",
    info: {
      intro1:
        "這個計劃適合那些仍在還在發展溝通和社交技巧的兒童。該計劃旨在提升兒童的社交和溝通技能，讓他們成功參與於不同社交環境。",
      intro2:
        "參加此計劃的學生將在個人和小組環境中接受一對一的訓練，以達致學習效果最大化。學生將接受一對一的個別訓練，以提升必要的溝通和社交技能。在小組訓練中，學生在一對一支援下，與另一位學生進行社交互動，密集地學習溝通和社交技巧。一旦學生準備好基礎社交及溝通技能，他們可以轉移至我們的恆常社交課程（師生比例1：2-3）學習進階社交技能。",
      pic: require("../img/treePlan/tree-plan_info.png"),
    },
  },
  servicesContent: {
    title: "計劃內容涵蓋",
    content: [
      {
        contentTitle: "基礎社交技巧",
        contentText:
          "社交技巧是兒童在學校中學習成功的基礎。唱和、遵循團體指示和與同輩互動等都是本計劃的目標。",
        pic: require("../img/treePlan/contentPic1.png"),
      },
      {
        contentTitle: "認知技能",
        contentText:
          "「Sprout Program」旨在為兒童在進入主流學校環境做好準備。訓練能提升認知、記憶、感知和專注力等核心技能，使學生能在學校環境中學以致用。",
        pic: require("../img/treePlan/contentPic2.png"),
      },
    ],
  },
};
