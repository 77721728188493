<template>
  <h1 v-show="false">小樹苗計劃</h1>
  <h2 v-show="false">Sprout Program</h2>
  <ServicesTop :info="curLangInfo.servicesInfo"></ServicesTop>
  <ServicesContent
    :servicesContent="curLangInfo.servicesContent"
  ></ServicesContent>
  <ConnectUs></ConnectUs>
</template>

<script setup>
import { computed } from "vue";
import { useLangStore } from "../../stores/index.ts";
import { treePlan_cn } from "../../js/chinese.js";
import { treePlan_en } from "../../js/english.js";
const langStore = useLangStore();
const curLangInfo = computed(() => {
  return langStore.curLang === "en" ? treePlan_en : treePlan_cn;
});
// components
import ServicesTop from "../../components/ServicesTop.vue";
import ServicesContent from "../../components/ServicesContent.vue";
import ConnectUs from "../../components/ConnectUs.vue";
</script>

<style lang="scss" scoped></style>
