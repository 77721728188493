<template>
  <div class="connectUs">
    <p class="connectUs-title">{{ curLangInfo.title }}</p>
    <p class="connectUs-tips">{{ curLangInfo.tips }}</p>
    <div class="connectUs-info">
      <div class="connectUs-info_our">
        <div class="address">
          {{ curLangInfo.address }}
          <div
            class="address-tips"
            :class="{ 'address-tips_en': langStore.curLang === 'en' }"
            v-for="(item, index) in curLangInfo.addressTips"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <div class="email">{{ curLangInfo.email }}</div>
        <div class="phone">{{ curLangInfo.phone }}</div>
      </div>
      <div class="connectUs-info_connect">
        <div class="name">
          <span>
            {{ curLangInfo.name }}
          </span>
          <input class="input" type="input" v-model="connectInfo.name" />
        </div>
        <div class="email">
          <span>
            {{ curLangInfo.emailOrPhone }}
          </span>
          <input class="input" type="input" v-model="connectInfo.emali" />
        </div>
        <div class="msg">
          <span> {{ curLangInfo.msg }} </span
          ><textarea
            class="input no-resize"
            type="textarea"
            v-model="connectInfo.msg"
          ></textarea>
        </div>
        <div class="send" @click="send()">
          {{ langStore.curLang === "en" ? "Send" : "發送" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { useLangStore } from "../stores/index.ts";
import { contactUs_cn } from "../js/chinese.js";
import { contactUs_en } from "../js/english.js";
const connectInfo = reactive({
  name: "",
  emali: "",
  msg: "",
});
const langStore = useLangStore();
const curLangInfo = computed(() => {
  return langStore.curLang === "en" ? contactUs_en : contactUs_cn;
});
const send = () => {
  if (connectInfo.name === "" || connectInfo.emali === "") {
    ElMessage({
      message: curLangInfo.value.errorTips,
      type: "error",
    });
    return;
  }
  let params = {
    name: connectInfo.name,
    contact: connectInfo.emali,
    message: connectInfo.msg,
  };
  params = JSON.stringify(params);
  ElMessage({
    message: curLangInfo.value.sendingTips,
    type: "warning",
  });
  try {
    axios({
      method: "post",
      url: "http://8.210.93.162:8088/sendMail",
      data: params,
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      ElMessage({
        message: curLangInfo.value.successTips,
        type: "success",
      });
      connectInfo.name = "";
      connectInfo.emali = "";
      connectInfo.msg = "";
    });
  } catch (error) {
    ElMessage({
      message: curLangInfo.value.failTips,
      type: "error",
    });
    console.error("请求出错", error);
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .connectUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 960px;
    height: 328px;
    margin: 80px auto;
    &-title {
      margin: 0;
      font-size: 44px;
      color: #48bbfc;
    }
    &-tips {
      margin: 4px 0 40px 0;
      font-size: 24px;
      color: #7f7f7f;
    }
    &-info {
      display: flex;
      justify-content: space-between;
      width: 800px;
      font-size: 16px;
      color: #7f7f7f;
      &_our {
        .address {
          &-tips {
            margin-left: 53px;
          }
          &-tips_en {
            margin-left: 39px;
          }
        }
        .email {
          margin: 20px 0;
        }
      }
      &_connect {
        position: relative;
        width: 400px;
        .name,
        .email {
          display: flex;
          flex-wrap: nowrap;
          width: 400px;
          height: 30px;
          span {
            width: 130px;
            white-space: pre-wrap;
          }
          .input {
            width: 270px;
            height: 30px;
            margin-left: 40px;
            padding: 0 12px;
            border-radius: 16px;
            background-color: #ffedb4;
            border: none;
            font-family: "COGFont", sans-serif;
            &:focus {
              outline: none;
            }
          }
        }
        .name,
        .email {
          margin: 10px 0;
          input {
            margin-left: 0 !important;
          }
        }
        .msg {
          display: flex;
          flex-wrap: nowrap;
          width: 400px;
          height: 30px;
          margin: 10px 0;
          line-height: 30px;
          span {
            width: 130px;
            white-space: pre-wrap;
          }
          textarea {
            width: 270px;
            height: 60px;
            margin-left: 40px;
            padding: 12px;
            border-radius: 20px;
            background-color: #ffedb4;
            border: none;
            margin-left: 0 !important;
            resize: none;
            font-family: "COGFont", sans-serif;
            &:focus {
              outline: none;
            }
          }
        }
        .send {
          position: absolute;
          top: 184px;
          left: 120px;
          width: 91px;
          height: 34px;
          margin-left: 10px;
          border-radius: 20px;
          text-align: center;
          line-height: 32px;
          color: #ae80ff;
          background: url("../img/contact/send.svg") center center no-repeat;
          background-size: 91px 34px;
          transition: background-image 0.3s ease, transform 0.2s ease;
          cursor: pointer;
          &:hover {
            color: #fff;
            background: url("../img/contact/send-active.svg") center center
              no-repeat;
            background-size: 91px 34px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .connectUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 480px;
    margin: 40px auto;
    padding-bottom: 40px;
    // overflow-x: hidden;
    &-title {
      margin: 0;
      font-size: 38px;
      color: #48bbfc;
    }
    &-tips {
      margin: 4px 0 40px 0;
      font-size: 20px;
      color: #7f7f7f;
    }
    &-info {
      width: 80%;
      font-size: 14px;
      color: #7f7f7f;
      &_our {
        .address {
          &-tips {
            margin-left: 11px;
          }
          &-tips_en {
            margin-left: 34px;
          }
        }
        .email {
          margin: 10px 0;
        }
      }
      &_connect {
        position: relative;
        margin-top: 30px;
        // width: 100%;
        .name,
        .email {
          width: 100%;
          height: 30px;
          line-height: 30px;
          padding-bottom: 20px;
          span {
            // display: inline-block;
            // margin-bottom: 20px;
            width: 130px;
            white-space: pre-wrap;
          }
          .input {
            display: block;
            width: 90%;
            height: 30px;
            // margin-left: 40px;
            padding: 0 12px;
            border-radius: 16px;
            background-color: #ffedb4;
            border: none;
            font-family: "COGFont", sans-serif;
            &:focus {
              outline: none;
            }
          }
        }
        .name,
        .email {
          margin: 10px 0;
          input {
            margin-left: 0 !important;
          }
        }
        .msg {
          width: 100%;
          height: 30px;
          margin: 10px 0;
          line-height: 30px;
          span {
            width: 130px;
            white-space: pre-wrap;
          }
          textarea {
            width: 90%;
            height: 60px;
            // margin-left: 40px;
            padding: 12px;
            border-radius: 20px;
            background-color: #ffedb4;
            border: none;
            margin-left: 0 !important;
            resize: none;
            font-family: "COGFont", sans-serif;
            &:focus {
              outline: none;
            }
          }
        }
        .send {
          position: absolute;
          top: 244px;
          left: -10px;
          width: 91px;
          height: 34px;
          margin-left: 10px;
          border-radius: 20px;
          text-align: center;
          line-height: 32px;
          color: #ae80ff;
          background: url("../img/contact/send.svg") center center no-repeat;
          background-size: 91px 34px;
          transition: background-image 0.3s ease, transform 0.2s ease;
          cursor: pointer;
          &:hover {
            color: #fff;
            background: url("../img/contact/send-active.svg") center center
              no-repeat;
            background-size: 91px 34px;
          }
        }
      }
    }
  }
}
</style>
