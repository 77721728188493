<template>
  <router-view />
</template>

<style scoped>
@font-face {
  font-family: "COGFont";
  src: url("../src/assets/fonts/jf-openhuninn-2.0.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}
</style>
